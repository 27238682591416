import React, { useState, useEffect, useContext, useRef } from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PageContext from '@context'
import { isInBrowser } from '@helpers'
import useStickyFilter from '@hooks/use-sticky-filter'
import ContentContainer from '@system/content-container'
import ResourceCard from '@system/resource-card'
import classNames from 'classnames'
import ResourceReferences from './resource-references'
import get from 'lodash/get'
import fetchFilteredStories from '@helpers/fetch-filtered-stories'
import { imgMaxWidths } from '@variables/global-variables'

const useStyles = makeStyles((theme) => ({
  // Filter
  filterBarOuterContainer: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      zIndex: 1100,
      '&:before': {
        background: theme.palette.background.paper,
        boxShadow:
          '0px 2px 2px -1px rgba(0,0,0,0.2), 0px 4px 3px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
        content: '""',
        height: '100%',
        left: '0',
        marginLeft: '50%',
        position: 'absolute',
        opacity: '0',
        top: '0',
        transform: 'translateX(-50%)',
        transition: '0.2s opacity, 0.2s visibility',
        visibility: 'hidden',
        width: 'var(--filter-bar-width)',
        zIndex: '-1',
      },
    },
  },
  filterBarOuterContainerSticky: {
    [theme.breakpoints.up('md')]: {
      '&:before': {
        opacity: '1',
        visibility: 'visible',
      },
    },
  },
  // Resources
  resourcesOuterContainer: {
    textAlign: 'left',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    maxWidth: '1056px',
    width: '100%',
    margin: '0 auto',
    padding: '25px 41.6px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      padding: '25px 0',
    },
  },
  // Webinars
  webinarsOuterContainer: {
    width: '100%',
    margin: '0 auto',
    padding: '0 41.6px',
    maxWidth: '1056px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  webinarsInnerContainer: {
    margin: '0 auto',
    display: 'flex',
    padding: '25px 0px',
    flexWrap: 'wrap',
    maxWidth: '100%',
    textAlign: 'left',
    justifyContent: 'space-around',
  },
  webinarsCards: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  webinarsCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 8px',
    width: '33.33333333%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

const ResourcesFilter = (props) => {
  const classes = useStyles()
  const pageContext = useContext(PageContext)
  const stickyContainerRef = useRef()
  const isSticky = useStickyFilter(stickyContainerRef.current)

  const formattedResourceStories = {
    companyType: '',
    constructionPhase: [],
    format: '',
    numberOfReferences: '',
    resources: [
      ...pageContext.resources,
      ...pageContext.webinars,
      ...pageContext.podcasts,
    ],
    sortBy: '',
    theme: [],
    topic: [],
    workflow: [],
    theme: '',
    isReferencingAllPublishedResources: true,
  }

  const formattedWebinarsStories = {
    webinarPastFuture: '',
    webinarCategory: [],
    segment: [],
    sortBy: '',
    workflow: [],
    product: '',
  }

  const watch = 'WATCH'
  const register = 'REGISTER'

  const webinarsStories =
    pageContext.webinars &&
    pageContext.webinars.map((webinar) => ({
      full_slug: webinar.full_slug,
      ...webinar.content,
    }))

  let webinarFilteredStories
  const { filtersMenu } = props.blok
  const [searchValue, setSearchValue] = useState(null)
  const [filters, setFilters] = useState([
    {
      filterBy: '',
      filterSelect: '',
    },
  ])

  const [filterLogicRules, setFilterLogicRules] = useState([
    {
      filterResultLogic: '',
      multiValueLogic: '',
      filterDataPath: '',
    },
  ])

  const broadensResults = 'broadensResults'
  const narrowsResults = 'narrowsResults'
  const filteredMenuList = get(props.blok.filtersMenu, '[0].filtersList')

  const filterByParam = []
  const filterSelectParam = []
  if (isInBrowser) {
    const href = pageContext.location
      ? pageContext.location.href
      : document.location.href
    const paramsString = new URL(href)
    for (var keys of paramsString.searchParams.keys()) {
      let isFilteredParams =
        filteredMenuList.some((params) => params.filterDataPath === keys) ||
        keys === 'theme'

      if (keys !== 'path' && keys.indexOf('_storyblok') < 0) {
        if (keys === 'all') {
          filterByParam.push({
            filterBy: 'sortBy',
            filterSelect: 'reverse-chronological',
          })
          filterSelectParam.push({
            filterResultLogic: '',
            multiValueLogic: '',
            filterDataPath: '',
          })
        } else if (isFilteredParams) {
          let filterLogicRules = filteredMenuList.filter(
            (filterList) => filterList.filterDataPath === keys
          )
          let filterResultLogic = get(
            filterLogicRules,
            '[0].filterResultsLogic'
          )
          let multipleValueLogic = get(
            filterLogicRules,
            '[0].multipleValuesLogic'
          )
          filterByParam.push({
            filterBy: keys,
            filterSelect: paramsString.searchParams.getAll(keys)[0].split(','),
          })
          if (filterResultLogic === undefined) {
            filterResultLogic = broadensResults
          }
          if (multipleValueLogic === undefined) {
            multipleValueLogic = narrowsResults
          }
          filterSelectParam.push({
            filterResultLogic: filterResultLogic,
            multiValueLogic: multipleValueLogic,
            filterDataPath: keys,
          })
        }
      }
    }
  }

  useEffect(() => {
    setFilters(filterByParam)
    setFilterLogicRules(filterSelectParam)
  }, [])

  const updateFilterLogicRules = (filterDataPath) =>
    filterLogicRules.filter(
      (filter) => filterDataPath.indexOf(filter.filterDataPath) !== -1 && filter
    )

  useEffect(() => {
    const filterDataPath = filters.map((filter) => filter.filterBy)

    if (!searchValue) {
      if (isFilterSelected) {
        if (isInBrowser) {
          const href = pageContext.location
            ? pageContext.location.origin + pageContext.location.pathname
            : document.location.href
          const paramsString = new URL(href)
          let filterParams = filters.filter(
            (filter) => filter.filterSelect.length > 0 && filter
          )
          filterParams.forEach((filterParam) => {
            if (filterParam.filterBy !== '') {
              paramsString.searchParams.append(
                filterParam.filterBy,
                filterParam.filterSelect
              )
            }
          })
          window.history.pushState({}, '', paramsString.href)
        }
      } else {
        window.history.pushState(
          {},
          '',
          pageContext.path || document.location.href
        )
      }
    }
    setFilterLogicRules(updateFilterLogicRules(filterDataPath))
  }, [filters])

  const isFilterSelected = filters.some(
    (eachFilter) =>
      eachFilter.filterSelect && eachFilter.filterSelect.length > 0
  )

  useEffect(() => {
    const shouldUpdateHiddenFieldsValue = isFilterSelected || searchValue
    props.updateHiddenFieldsValue(shouldUpdateHiddenFieldsValue)
  }, [filters, searchValue])

  const onChangeFilters = (event, filterName) => {
    const emptyString = ''
    const value = event.target.value || emptyString
    setSearchValue(null)
    if (value === emptyString) {
      setFilters((oldFilters) =>
        oldFilters.filter((item) => item.filterBy !== filterName)
      )
      setFilterLogicRules((oldFilterLogicRules) =>
        oldFilterLogicRules.filter(
          (filterResultLogic) => filterResultLogic.filterDataPath !== filterName
        )
      )
    } else {
      setFilters((oldFilters) => {
        const matchFilters = oldFilters.some(
          (filter) => filter.filterBy === filterName
        )
        return matchFilters
          ? oldFilters.map((filter) => {
              if (filter.filterBy === filterName) filter.filterSelect = value
              return filter
            })
          : [...oldFilters, { filterBy: filterName, filterSelect: value }]
      })
    }
  }

  const onChangeSearchText = (event) => {
    const searchedText = event.target.value
    setSearchValue(searchedText)
    setFilters([
      {
        filterBy: '',
        filterSelect: '',
      },
    ])
    setFilterLogicRules([
      { filterResultLogic: '', multiValueLogic: '', filterDataPath: '' },
    ])
  }

  const resetFilters = () => {
    window.history.pushState({}, '', pageContext.path || document.location.href)
    setSearchValue(null)
    setFilters([
      {
        filterBy: '',
        filterSelect: '',
      },
    ])
    setFilterLogicRules([
      { filterResultLogic: '', multiValueLogic: '', filterDataPath: '' },
    ])
    props.updateHiddenFieldsValue(false)
  }

  const fetchFilterLogicRules = (
    filterResultLogic,
    multiValueLogic,
    filterDataPath
  ) => {
    setSearchValue(null)
    setFilterLogicRules((oldFilterLogicRules) => {
      const haveOldFiltersMatched = oldFilterLogicRules.some(
        (filterResultLogic) =>
          filterResultLogic.filterDataPath === filterDataPath
      )
      return haveOldFiltersMatched
        ? [...oldFilterLogicRules]
        : [
            ...oldFilterLogicRules,
            {
              filterResultLogic: filterResultLogic,
              multiValueLogic: multiValueLogic,
              filterDataPath: filterDataPath,
            },
          ]
    })
  }

  filters.forEach((filter) => {
    const filteredStories = props.isWebinarsModule
      ? formattedWebinarsStories
      : formattedResourceStories

    return (filteredStories[`${filter.filterBy}`] = filter.filterSelect)
  })

  const filterLogic = filterLogicRules.some(
    (filter) => filter.filterDataPath !== ''
  )
    ? filterLogicRules
    : filterSelectParam

  if (props.isWebinarsModule) {
    const isFilterSelected = filters.some(
      (eachFilter) =>
        eachFilter.filterSelect && eachFilter.filterSelect.length > 0
    )
    if (isFilterSelected || !!searchValue) {
      const allFilteredWebinars = fetchFilteredStories(
        { ...formattedWebinarsStories, filterLogicRules: filterLogic },
        webinarsStories,
        ...searchValue
      )
      const upcomingWebinars = allFilteredWebinars.filter(
        (eachStory) =>
          eachStory.webinarVideo && eachStory.webinarVideo.length === 0
      )
      const onDemandWebinars = allFilteredWebinars.filter(
        (eachStory) =>
          eachStory.webinarVideo && eachStory.webinarVideo.length > 0
      )
      webinarFilteredStories = [...upcomingWebinars, ...onDemandWebinars]
    } else {
      webinarFilteredStories = null
    }
  }

  const getWebinarsHyperLink = (webinar) => {
    return !!webinar.webinarVideo && webinar.webinarVideo.length > 0
      ? watch
      : register
  }

  return (
    <SbEditable content={props.blok}>
      <Box
        className={classNames(classes.filterBarOuterContainer, {
          [classes.filterBarOuterContainerSticky]: isSticky,
        })}
        ref={stickyContainerRef}
      >
        {!!filtersMenu &&
          filtersMenu.map((filterMenu) => (
            <ContentContainer key={filterMenu._uid}>
              {renderBlok({
                ...filterMenu,
                filters,
                onChangeSearchText,
                onChangeFilters,
                fetchFilterLogicRules,
                isResourcesModule: !props.isWebinarsModule,
                isWebinarsModule: props.isWebinarsModule,
                searchValue,
                resetFilters,
              })}
            </ContentContainer>
          ))}
      </Box>
      {props.isResourcesIndexView && (
        <Box
          className={classes.resourcesOuterContainer}
          container="true"
          flexWrap="wrap"
        >
          <ResourceReferences
            blok={formattedResourceStories}
            filterLogicRules={
              filterLogicRules.some((filter) => filter.filterDataPath !== '')
                ? filterLogicRules
                : filterSelectParam
            }
            searchValue={searchValue}
            shouldDisplayAllResources={true}
          />
        </Box>
      )}
      {props.isWebinarsIndexView && props.isWebinarsModule && (
        <Box className={classes.webinarsOuterContainer}>
          <Box className={classes.webinarsInnerContainer}>
            <Box className={classes.webinarsCards}>
              {webinarFilteredStories &&
                webinarFilteredStories.map((webinar) => {
                  const webinarsHyperLink = getWebinarsHyperLink(webinar)
                  return (
                    <Box
                      className={classNames(classes.webinarsCard)}
                      key={webinar._uid}
                    >
                      <ResourceCard
                        blok={{
                          ...webinar,
                          textToHyperlink: webinarsHyperLink || 'Read',
                          isWebinarsModule: props.isWebinarsModule,
                          description: webinar.subtitle,
                          link: webinar.full_slug,
                        }}
                        imgMaxWidth={imgMaxWidths.resourceCard}
                      />
                    </Box>
                  )
                })}
            </Box>
          </Box>
        </Box>
      )}
    </SbEditable>
  )
}

export default ResourcesFilter
